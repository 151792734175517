<script setup lang="ts">
/**
 * @author Beka Ckhaidze
 */
import { ref, watch } from "vue";


import Slider from "@vueform/slider";
import Input from "@/components/regularComponents/FormComponents/Input.vue";

import "@vueform/slider/themes/default.css";

const props = defineProps<{
  min?: number;
  max?: number;
  teaser: string;
  minText: string;
  maxText: string;
  isPrice: boolean;
}>();

const emit = defineEmits(["onChange"]);

const from = ref(props?.min?.toString() || "0");
const to = ref(props?.max?.toString() || "0");
const range = ref([from.value, to.value]);

const onRangeChange = (param: number[]) => {
  from.value = param[0].toString() || "0";
  to.value = param[1].toString() || "0";
  emit("onChange", [from.value, to.value]);
};

watch([from, to], (n) => {
  range.value[0] = n[0];
  range.value[1] = n[1];
});
</script>

<template>
  <div class="price-range">
    <p class="price-range__text">{{ $t(teaser?.toLowerCase()) || $t("choose price") }}</p>
    <div class="price-range__slider">
      <Slider
        v-model="range"
        v-bind="{
          tooltips: false,
          min: min,
          max: max,
        }"
        @change="onRangeChange"
      />
    </div>

    <div class="price-range__inputs d-flex al-center">
      <Input class="price-range__inputs--from" :label="$t(minText?.toLowerCase())" v-model="from" />
      <Input class="price-range__inputs--to" :label="$t(maxText?.toLowerCase())" v-model="to" />
    </div>
  </div>
</template>

<style lang="scss">
.price-range {
  padding: 24px 16px;
  padding-bottom: 32px;

  &__text {
    color: $border-active;
    font-weight: 400;
    font-size: 26px;
    line-height: 20px;

    @include maxq(desktop-2k) {
      font-size: 20px;
    }

    @include maxq(desktop-lg) {
      font-size: 16px;
    }
  }

  &__slider {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 26px;
    --slider-connect-bg: #000;
    --slider-bg: #e0e0e0;
    --slider-handle-width: 45px;
    --slider-handle-shadow: none;
    --slider-handle-shadow-active: none;

    @include maxq(desktop-lg) {
      --slider-handle-width: 27px;
      padding: 0 13px;
    }

    .slider-handle {
      --slider-handle-height: 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='27' height='21' viewBox='0 0 27 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='26' height='20' fill='white' stroke='black'/%3E%3Crect x='10' y='7' width='1' height='7' fill='black'/%3E%3Crect x='13' y='7' width='1' height='7' fill='black'/%3E%3Crect x='16' y='7' width='1' height='7' fill='black'/%3E%3C/svg%3E");
      height: 35px;
      border-radius: 0;
      top: calc(
        (var(--slider-handle-height, 16px) - var(--slider-height, 6px)) / 2 * -1 + -3px
      );
      background-size: 100%;

      @include maxq(desktop-lg) {
        --slider-handle-height: 16px;
        height: 21px;
        background-size: unset;
      }
    }
  }

  &__inputs {
    gap: 16px;
    .custom-input {
      &__content {
        background-color: $disabled !important;
        cursor: text !important;
      }

      &__input-label {
        color: #252525 !important;
        opacity: 0.6 !important;
      }

      &__value {
        color: #252525 !important;
        cursor: text !important;
      }
    }
    .custom-input__input {
      justify-content: flex-start !important;
    }
  }
}
</style>
