<script setup lang="ts">
/**
 * @author Beka Chkhaidze, Irakli Kandelaki
 */

/** IMPORT HOOKS */
import { computed, onBeforeMount, ref, watch, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useMainStore } from "@/store/main/index";
import { useBuildingStore } from "@/store/building";

/** IMPORT TYPES */
import type { SelectInterface } from "@/types/FormInterface";
import type { FiltersState } from "./ts/interfaces";
import type { Swiper as SwiperType } from "swiper/types";
import type { IndxInterface } from "@/store/main/types/StateType";

import "swiper/css";

/** IMPORT COMPONENTS */
import Button from "@/components/regularComponents/UIComponents/Button.vue";
import SelectInput from "@/components/regularComponents/FormComponents/SelectInput.vue";
import BurgerDropdown from "@/components/regularComponents/UIComponents/BurgerDropdown/BurgerDropdown.vue";
import PriceRange from "./PriceRange.vue";
import X from "../SVG/X.vue";

const indx = useState<IndxInterface>("data");

const router = useRouter();
const route = useRoute();

const buildingStore = useBuildingStore();
const mainStore = useMainStore();

const props = defineProps<{
  onlyProjects?: boolean;
  floorRenderRef?: HTMLElement;
  floorRenderSVGGroups?: SVGGElement[];
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const projectListState = ref<{
  titleActive: boolean;
  projectSlug: string;
}>({
  titleActive: true,
  projectSlug: "",
});

const state = ref<FiltersState>({
  floor: null,
  room: null,
  price: [],
  currency: null,
  size: [],
  flatType: null,
});

const activeFlatIds: Ref<number[]> = ref([]);
const priceRangeKey: Ref<number> = ref(0);
const sizeRangeKey: Ref<number> = ref(0);
const flatIds: Ref<number[]> = ref([]);
const swiper = ref<SwiperType>();

const formatter = (title: string | number, value: string | number) => {
  title = title?.toString();
  value = value?.toString();
  return {
    title,
    value,
  };
};

const isDesktop = computed(() => mainStore.windowWidth > mainStore.breakpoints.mobile);
const routeFullPath = computed(() => route?.fullPath);
const isProjectRenderPage = computed(() => !Boolean(route.params?.slugs?.[2]));

const floors = computed(() =>
  buildingStore.selectedProjectFloors
    ?.filter(
      (el) => {
        const blockSlug = route.params.slugs?.[2]?.split("-")?.[1]?.toLowerCase();

        const selectedBlockId = buildingStore.selectedProjectBlocks?.find(x => x.slug == blockSlug)?.id;

        return (el?.development_flats_status?.available ||
          el?.development_flats_status?.reserved) &&
          el?.conf !== "sold" &&
        (!selectedBlockId || selectedBlockId == el?.block_id)
      }
    )
    ?.map((el) => {
      return formatter(el.floor_number, el.floor_number);
    })
);

const rooms = computed(() => {
  let filteredFlats = buildingStore?.selectedFloorFlats?.filter(
    (flat) => !flat?.conf?.includes("sold") && !flat?.conf?.includes("reserved")
  );
  if (flatIds.value?.length) {
    filteredFlats = filteredFlats?.filter((flat) => flatIds.value.includes(flat.id));
  }
  return filteredFlats?.map((el) => formatter(el.flat_number, el.flat_number));
});

const everyPriceHidden = computed(() => {
  return buildingStore?.selectedFloorFlats?.every((x) => x?.hide_price?.hide_price);
});

const allCurrencies = [
  formatter("USD", "USD"),
  formatter("GEL", "GEL"),
  formatter("EUR", "EUR"),
];

const currencies = computed(() =>
  allCurrencies.filter((el) => el.value != state.value.currency?.value)
);

const flatTypes = computed(() => {
  const formatted = buildingStore?.selectedFloor?.flatTypeTaxonomies?.map((el) =>
    formatter(el?.title, el.id)
  );

  return formatted;
});

const onlyOneAvailableFlat = computed(() => {
  const filtered = buildingStore?.selectedFloorFlats?.filter(
    // @ts-ignore
    (el) => (el.conf as string) === ""
  );

  return filtered?.length === 1;
});

const projectItems = computed(() => {
  if (projectListState.value.projectSlug && buildingStore?.getProjectNames?.length) {
    const index = buildingStore.getProjectNames.findIndex((proj) =>
      proj.value.includes(projectListState.value.projectSlug)
    );

    if (index > -1) {
      projectListState.value.titleActive = false;
      let items = [...buildingStore?.getProjectNames];
      items[index].isActive = true;

      return [...items.splice(index, 1), ...items];
    }
  }
  return buildingStore.getProjectNames || [];
});

const priceRangeValues = computed(() => buildingStore.selectedFloor?.filterRanges?.price);

const formattedPriceRange = computed({
  get() {
    const val = state.value.price?.join("-") || "";
    return formatter(val, val);
  },
  set() {},
});

const formattedSizeRange = computed({
  get() {
    const val = state.value.size?.join("-") || "";
    return formatter(val, val);
  },
  set() {},
});

const sizeRangeValues = computed(
  () => buildingStore.selectedFloor?.filterRanges?.area_m2
);

const isFilterDisabled = computed(
  () =>
    !state.value.room &&
    !state.value.price?.length &&
    !state.value.size?.length &&
    !state.value.currency &&
    !state.value.flatType
);

const onSelect = (
  e: SelectInterface | undefined,
  selectType: string,
  range?: string[]
) => {
  if (range) {
    setTimeout(() => {
      if (!swiper.value) return;
      swiper.value.updateSize();
      swiper.value.updateSlides();
    }, 500);
  } else {
    emit("close");
  }
  if (selectType === "floor") {
    const newVal: number = Number(e?.value);

    const blockSlug = route.params.slugs?.[2]?.split("-")?.[1]?.toLowerCase();

    const selectedBlockId = buildingStore.selectedProjectBlocks?.find(x => x.slug == blockSlug)?.id;

    const matchedFloor = buildingStore?.selectedProjectFloors?.find(
      (floor) => floor?.floor_number === newVal && (!selectedBlockId || selectedBlockId == floor.block_id)
    );

    if (matchedFloor?.route) {
      router.push(matchedFloor?.route);
      state.value.room = null;
      state.value.price = [];
      state.value.size = [];
      state.value.flatType = null;
    }
  } else {
    if (selectType === "size" && range?.length) {
      state.value.size = range;
      setActiveFlats(true, selectType, e);
    } else if (selectType === "price" && range?.length) {
      state.value.price = range;
      setActiveFlats(true, selectType, e);
    } else if (selectType === "currency") {
      const currencyVal = e?.value as "gel" | "eur" | "usd";
      buildingStore.currency = currencyVal;
    } else {
      setActiveFlats(false, selectType, e);
    }
  }
};

const clearFilters = () => {
  let floorNum: string | undefined;
  if ((route?.params?.slugs?.[3] || route?.params?.slugs?.[2]) && typeof ((route?.params?.slugs?.[3] || route?.params?.slugs?.[2])) === "string") {
    floorNum = (route?.params?.slugs?.[3] || route?.params?.slugs?.[2])?.split("-").at(-1);
  }

  state.value.floor = floors.value?.find((f) => f.value == floorNum) || null;
  state.value.room = null;
  state.value.price = [];
  state.value.size = [];
  state.value.flatType = null;
  state.value.currency = null;
  sizeRangeKey.value += 1;
  priceRangeKey.value += 1;

  const flatGroupEls:
    | NodeListOf<SVGGElement>
    | undefined = props.floorRenderRef?.querySelectorAll("g");
  if (!flatGroupEls?.length) return;
  flatGroupEls.forEach((el) => {
    el.classList.remove("selected-flat");
  });
};

const onProjectSelect = (project: SelectInterface) => {
  if (project?.value) {
    router.push(project.value)
  };
};

const goBack = () => {
  if (!indx.value) return "";
  const matchedMenu = indx.value?.menus?.find(
    (menu) => menu.id === buildingStore.selectedProject?.relatedSitemap
  );
  let url = "";
  if (matchedMenu) {
    url = matchedMenu?.full_url;
  }

  router.push({ path: url });
};

const setActiveFlats = (
  isRange: boolean,
  selectType: string,
  e: SelectInterface | undefined
) => {
  const flatGroupEls:
    | NodeListOf<SVGGElement>
    | undefined = props.floorRenderRef?.querySelectorAll("g");
  if (!flatGroupEls?.length) return;
  activeFlatIds.value = [];
  flatGroupEls.forEach((el) => {
    let isActive: boolean = false;
    const groupKey: string | null = el?.getAttribute("id");
    if (!groupKey) return;
    const roomNumber: string | null = el?.getAttribute("number");
    const roomId: string | null = el?.getAttribute("flat_id");
    const price: string | null = el?.getAttribute("price");
    const size: string | null = el?.getAttribute("area");
    const flatTypeId: string | null = el?.getAttribute("flattype_id");
    const hidePrice: number | null = Number(el?.getAttribute("hide_price"));
    if (!roomNumber || !size || !price || !flatTypeId) return;

    for (const [key, value] of Object.entries(state.value)) {
      let selectValue;

      if (!isRange) {
        if (!(value as SelectInterface)?.value) continue;

        selectValue = value as SelectInterface;

        if (key === "floor") {
          continue;
        }

        if (
          key === "room" &&
          selectValue?.value !== roomNumber &&
          selectValue.value?.length
        ) {
          break;
        }

        if (
          key === "flatType" &&
          selectValue?.value !== flatTypeId &&
          selectValue?.value?.length
        )
          break;
      } else {
        if (!value) continue;

        selectValue = Array.from(value as string[]);
        if (!selectValue.length) continue;

        if (
          hidePrice ||
          (selectValue?.length &&
            key === "price" &&
            buildingStore.getPrice(Number(price), true) < Number(selectValue?.[0])) ||
          (selectValue?.length &&
            key === "price" &&
            buildingStore.getPrice(Number(price), true) > Number(selectValue?.[1]))
        )
          break;

        if (
          (selectValue?.length &&
            key === "size" &&
            Number(size) < Number(selectValue?.[0])) ||
          (selectValue?.length &&
            key === "size" &&
            Number(size) > Number(selectValue?.[1]))
        )
          break;
      }

      isActive = true;
      if (!activeFlatIds.value?.includes(Number(roomId))) {
        activeFlatIds.value.push(Number(roomId));
      }
    }

    if (isActive) {
      el.classList.add("selected-flat");
    } else {
      el.classList.remove("selected-flat");
    }
  });

  type KeysType = keyof typeof state.value;
  for (const key of Object.keys(state.value)) {
    // unset if clicking selected one
    // if (key === selectType && e?.value === undefined) {
    //   state.value[key as KeysType] = null;
    // }
    if (key === selectType) continue;
    if (key == "floor") continue;
    if (key === "price") priceRangeKey.value += 1;
    if (key === "size") sizeRangeKey.value += 1;
    state.value[key as KeysType] = null;
  }
};

const onSwiper = (e: SwiperType) => {
  swiper.value = e;
};

watch(
  routeFullPath,
  (n) => {
    projectListState.value.projectSlug = route?.params?.slugs?.[1] as string;
    clearFilters();

    if (props.floorRenderSVGGroups) {
      setTimeout(() => {
        flatIds.value = [];
        props.floorRenderSVGGroups?.forEach((flat) => {
          flatIds.value.push(Number(flat?.getAttribute("flat_id")));
        });
      }, 1200);
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => mainStore?.lang,
  () => {
    buildingStore.getProjects({
      forceRefetch: true,
      params: { limit: 1000 },
    });
  }
);

onBeforeMount(async () => {
  if (!buildingStore.projectList?.length) {
    await buildingStore.getProjects({
      forceRefetch: true,
      params: { limit: 1000 },
    });
  }
});
</script>

<template>
  <div v-if="isDesktop" class="floor-filters d-flex al-center z-9">
    <Swiper
      slides-per-view="auto"
      :grab-cursor="true"
      :space-between="8"
      noSwipingSelector=".price-range"
      @swiper="onSwiper"
    >
      <div v-if="isProjectRenderPage">
        <BurgerDropdown
          class="floor-filters-type h-full"
          :items="projectItems"
          @select="onProjectSelect"
          :titleActive="projectListState?.titleActive"
        />
      </div>
      <SwiperSlide v-else>
        <BurgerDropdown
          class="floor-filters-type h-full"
          :items="projectItems"
          @select="onProjectSelect"
          :titleActive="projectListState?.titleActive"
        />
      </SwiperSlide>

      <SwiperSlide>
        <Button @click="goBack" :btn-type="8" icon="arrow" btn-text="back" />
      </SwiperSlide>

      <template v-if="!onlyProjects">
        <SwiperSlide v-if="floors?.length">
          <SelectInput
            v-model="state.floor"
            class="w-full"
            label="Floor"
            :items="floors"
            @select="(e) => onSelect(e, 'floor')"
          />
        </SwiperSlide>

        <SwiperSlide v-if="rooms?.length">
          <SelectInput
            v-model="state.room"
            class="w-full"
            label="Flat"
            :items="rooms"
            @select="(e) => onSelect(e, 'room')"
          />
        </SwiperSlide>

        <SwiperSlide
          v-if="
            !onlyOneAvailableFlat &&
            !everyPriceHidden &&
            buildingStore.getPrice(priceRangeValues?.min, true) !==
              buildingStore.getPrice(priceRangeValues?.max, true)
          "
        >
          <SelectInput
            v-model="formattedPriceRange"
            customBody
            :class="[
              'w-full price-range-select',
              { 'price-range-select--has-value': !!formattedPriceRange.value },
            ]"
            label="Price"
            :items="rooms"
            :key="priceRangeKey"
          >
            <template #custom>
              <PriceRange
                v-if="priceRangeValues?.min && priceRangeValues?.max"
                :isPrice="true"
                teaser="Choose price range"
                minText="minimum price"
                maxText="maximum price"
                :min="buildingStore.getPrice(priceRangeValues.min, true)"
                :max="buildingStore.getPrice(priceRangeValues.max, true)"
                @onChange="(e) => onSelect(undefined, 'price', e)"
              />
            </template>
          </SelectInput>
        </SwiperSlide>

        <SwiperSlide
          v-if="
            !onlyOneAvailableFlat &&
            !everyPriceHidden &&
            sizeRangeValues?.min !== sizeRangeValues?.max
          "
        >
          <SelectInput
            v-model="formattedSizeRange"
            custom-body
            :class="[
              'w-full price-range-select',
              { 'price-range-select--has-value': !!formattedSizeRange.value },
            ]"
            :label="$t('size') + ' ' + $t('m') + '<sup>2</sup>'"
            :noTranslate="true"
            :items="rooms"
            :key="sizeRangeKey"
          >
            <template #custom>
              <PriceRange
                v-if="sizeRangeValues?.min && sizeRangeValues?.max"
                :isPrice="false"
                teaser="Choose size range"
                minText="minimum size"
                maxText="maximum size"
                :min="sizeRangeValues.min"
                :max="sizeRangeValues.max"
                @onChange="(e) => onSelect(undefined, 'size', e)"
              />
            </template>
          </SelectInput>
        </SwiperSlide>

        <SwiperSlide v-if="flatTypes?.length">
          <SelectInput
            v-model="state.flatType"
            class="w-full custom-select-filter--types"
            label="Types"
            :items="flatTypes"
            @select="(e) => onSelect(e, 'flatType')"
          />
        </SwiperSlide>

        <SwiperSlide v-if="!everyPriceHidden">
          <SelectInput
            v-model="state.currency"
            class="w-full"
            label="Currency"
            :items="currencies"
            @select="(e) => onSelect(e, 'currency')"
          />
        </SwiperSlide>

        <SwiperSlide
          :class="{
            'op-6 ev-none': isFilterDisabled,
          }"
        >
          <div
            class="floor-filters__clear pointer d-flex gap-8 al-center"
            @click="clearFilters"
          >
            <p class="text op-6">{{ $t("clear") }}</p>
            <X />
          </div>
        </SwiperSlide>
      </template>
    </Swiper>
  </div>

  <div v-else class="floor-filters--mob-view">
    <template v-if="onlyProjects">
      <BurgerDropdown
        class="floor-filters-type"
        :items="projectItems"
        @select="onProjectSelect"
        :titleActive="projectListState?.titleActive"
      />
    </template>
    <template v-else>
      <SelectInput
        v-if="floors?.length"
        v-model="state.floor"
        class="w-full"
        label="Floor"
        useSlideUpDown
        :items="floors"
        @select="(e) => onSelect(e, 'floor')"
      />

      <SelectInput
        v-if="rooms?.length"
        v-model="state.room"
        class="w-full"
        label="Room"
        useSlideUpDown
        :items="rooms"
        @select="(e) => onSelect(e, 'room')"
      />

      <SelectInput
        v-if="rooms?.length && !everyPriceHidden && !onlyOneAvailableFlat"
        v-model="formattedPriceRange"
        custom-body
        :class="[
          'w-full price-range-select',
          { 'price-range-select--has-value': !!formattedPriceRange.value },
        ]"
        label="Price"
        :items="[]"
        useSlideUpDown
        @select="(e) => onSelect(e, 'price')"
      >
        <template #custom>
          <PriceRange
            v-if="priceRangeValues?.min && priceRangeValues?.max"
            :isPrice="true"
            teaser="Choose price range"
            minText="minimum price"
            maxText="maximum price"
            :min="buildingStore.getPrice(priceRangeValues.min, true)"
            :max="buildingStore.getPrice(priceRangeValues.max, true)"
            @onChange="(e) => onSelect(undefined, 'price', e)"
          />
        </template>
      </SelectInput>

      <SelectInput
        v-if="!onlyOneAvailableFlat && !everyPriceHidden && rooms?.length"
        v-model="formattedSizeRange"
        custom-body
        class="w-full price-range-select"
        :class="[
          'w-full price-range-select',
          { 'price-range-select--has-value': !!formattedSizeRange.value },
        ]"
        :label="$t('size') + ' ' + $t('m') + '<sup>2</sup>'"
        :noTranslate="true"
        :items="rooms"
        useSlideUpDown
        @select="(e) => onSelect(e, 'size')"
      >
        <template #custom>
          <PriceRange
            v-if="sizeRangeValues?.min && sizeRangeValues?.max"
            :isPrice="false"
            teaser="Choose size range"
            minText="minimum size"
            maxText="maximum size"
            :min="sizeRangeValues.min"
            :max="sizeRangeValues.max"
            @onChange="(e) => onSelect(undefined, 'size', e)"
          />
        </template>
      </SelectInput>

      <SelectInput
        v-if="flatTypes?.length"
        v-model="state.flatType"
        class="w-full"
        label="Types"
        useSlideUpDown
        :items="flatTypes"
        @select="(e) => onSelect(e, 'flatType')"
      />

      <SelectInput
        v-if="!everyPriceHidden"
        v-model="state.currency"
        class="w-full"
        label="Currency"
        :items="currencies"
        useSlideUpDown
        @select="(e) => onSelect(e, 'currency')"
      />

      <div
        class="floor-filters__clear pointer d-flex gap-8 al-center j-btw"
        :class="{
          'op-6 ev-none': isFilterDisabled,
        }"
        @click="clearFilters"
      >
        <p class="text op-6">{{ $t("clear") }}</p>
        <X />
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./style/filters.scss" />
